import React from "react";
import logoBig from "assets/images/amnis_logo.gif";
import logoSmall from "assets/images/amnis_icon.gif";
import styles from "./AmnisLoader.module.sass";

interface IAmnisLoaderProps {
    big?: boolean;
}

const AmnisLoader: React.FC<IAmnisLoaderProps> = ({ big }) => (
    <div className={styles.logo}>
        <img
            className={big ? styles.imageBig : styles.imageSmall}
            src={big ? logoBig : logoSmall}
            alt="Amnis logo"
        />
    </div>
);

export default AmnisLoader;
