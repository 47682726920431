export const translate = (
    value: string,
    variables?: {},
    callback?: (value: string) => void
) => {
    let newValue = "";
    const newCallback = (translatedValue: string) => {
        if (callback) {
            callback(translatedValue);
        }
    };
    (window as any).Localize.translate(value, variables, (translation: any) => {
        newValue = translation;
        newCallback(translation);
    });

    return newValue;
};
