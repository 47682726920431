import { FORMAT_DATE } from "constants/date";
import { format } from "date-fns";

export const formatDate = (
    date: string | Date,
    dateFormat?: string,
    locale?: Locale
) => {
    if (!date) return "";
    let dateStr: string;
    if (date instanceof Date) {
        dateStr = format(date, "yyyy-MM-dd");
    } else {
        dateStr = date;
    }
    const temp = new Date(
        dateStr.includes("T") ? dateStr.split("T")[0] : dateStr
    );
    const newDate = new Date(
        temp.getTime() + temp.getTimezoneOffset() * 60 * 1000
    );
    return format(newDate, dateFormat || FORMAT_DATE, { locale });
};
