import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router";
import classNames from "classnames";
import qs from "qs";

import { paymentOptions } from "./data";
import { useGetPackages } from "./useGetPackages";
import { PackageCard, PricingComparison, PaymentOptionButtons } from "./parts";
import styles from "./PricingPage.module.scss";
import AmnisLoader from "components/AmnisLoader/AmnisLoader";
import { useLanguage } from "context/LanguageProvider";

const DEFAULT_CURRENCY_MAPPING: { [key: string]: string } = {
    CZ: "CZK",
    CH: "CHF",
    PL: "PLN",
};

const PricingPage = () => {
    const location = useLocation();
    const [activePaymentOption, setActivePaymentOption] = useState(
        paymentOptions[1].id
    );
    const { selectedLanguageParams } = useLanguage();
    const [currency, setCurrency] = useState("CHF");
    const [currencies, setCurrencies] = useState<
        { "@id": string; code: string }[]
    >([]);
    const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });
    const { updateLanguage } = useLanguage();

    useEffect(() => {
        if (params.language) {
            updateLanguage(params.language.toString().toLowerCase());
        }
    }, [params, updateLanguage]);

    const { data: availablePackages, isLoading: isPackagesLoading } = useGetPackages();

    const selectableCurrencies = currencies.filter(
        (c) => c["@id"].split("/").pop() !== currency
    );

    const handleContactClick = () => {
        window.parent.postMessage("Intercom.show", "*");
    };

    const getDefaultCurrency = useCallback(
        (curr: string) => {
            if (currencies?.some((c) => c.code === curr)) {
                return curr;
            }
            return "EUR";
        },
        [currencies]
    );

    useEffect(() => {
        const params = new URLSearchParams(location?.search);
        const countryCode = params.get("countrycode");
        if (!currencies) return;

        const defaultCurrency = countryCode
            ? DEFAULT_CURRENCY_MAPPING[countryCode.toUpperCase()]
            : undefined;
        if (
            countryCode &&
            currencies.find((c) => c.code === defaultCurrency) &&
            defaultCurrency
        ) {
            setCurrency(defaultCurrency);
        } else if (countryCode) {
            setCurrency("EUR");
        } else {
            setCurrency(
                getDefaultCurrency(
                    selectedLanguageParams?.defaultCurrency || ""
                )
            );
        }
    }, [
        currencies,
        getDefaultCurrency,
        selectedLanguageParams,
        location?.search,
    ]);

    useEffect(() => {
        if (availablePackages && availablePackages.packages) {
            const availableCurrenciesMap = new Map<string, number>();
            const packagesWithCurrencies = Object.keys(availablePackages.packages).filter(
                (pckg) => Object.keys(availablePackages.packages[pckg].prices).length !== 0
            );
            packagesWithCurrencies.forEach((pckg) => {
                Object.keys(availablePackages.packages[pckg].prices).forEach((curr) => {
                    const currentCount = availableCurrenciesMap.get(curr) ?? 0;
                    availableCurrenciesMap.set(curr, currentCount + 1);
                });
            });
            setCurrencies(
                Array.from(availableCurrenciesMap)
                    .filter(
                        ([key, value]) =>
                            value === packagesWithCurrencies.length
                    )
                    .map(([key, value]) => ({
                        "@id": `/web_api/currency/${key.toUpperCase()}`,
                        code: key.toUpperCase(),
                    }))
            );
        }
    }, [availablePackages]);

    const handleSelectPackage = () => {
        window
            .open("https://app.amnistreasury.com/create-an-account", "_blank")
            ?.focus();
    };

    if (isPackagesLoading) {
        return <AmnisLoader />;
    }

    return (
        <>
            <Container fluid className={classNames(styles.container)}>
                <PaymentOptionButtons
                    activePaymentOption={activePaymentOption}
                    setActivePaymentOption={setActivePaymentOption}
                />
                <Col
                    xs={12}
                    className={classNames(
                        "mt-3 d-flex justify-content-end align-items-center",
                        styles.currencyDropdownContainer
                    )}
                >
                    <span className={styles.currencyPickerLabel}>Currency</span>
                    <Dropdown
                        className={styles.currencyPicker}
                        data-notranslate
                    >
                        <Dropdown.Toggle id="currency-dropdown">
                            {
                                currencies.find(
                                    (c) =>
                                        c["@id"].split("/").pop() === currency
                                )?.code
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {selectableCurrencies.map((c) => (
                                <Dropdown.Item
                                    onClick={() =>
                                        setCurrency(
                                            c["@id"].split("/")?.pop() || ""
                                        )
                                    }
                                    key={c["@id"]}
                                >
                                    {c.code}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                {availablePackages?.packages ? (
                    <Col
                        xs={12}
                        className={classNames(
                            styles.packageCardContainer,
                            "d-flex justify-content-center flex-wrap"
                        )}
                    >
                        {Object.values(availablePackages.packages).sort((d1, d2) => d1.order > d2.order ? 1 : -1).map((packageDetails) => (
                            <PackageCard
                                packageData={packageDetails}
                                key={packageDetails["@id"]}
                                activePaymentOption={activePaymentOption}
                                currency={currency}
                                highlight={packageDetails.recommended}
                                handleClick={handleSelectPackage}
                                isActivePackage={false}
                                buttonText={"Select"}
                                noPaymentFees={false}
                            />
                        ))}
                    </Col>
                ) : null}
                <Col xs={12} className={styles.betterOptions}>
                    <div className={styles.diamondIcon}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className={styles.betterOptionsText}>
                        <h3 className="title">
                            Couldn&apos;t find a suitable plan?
                        </h3>
                        <p>
                            Our team helps you to find a package customized to
                            your specific needs.
                        </p>
                    </div>
                    <div className={styles.betterOptionsButtonContainer}>
                        <Button onClick={handleContactClick}>Contact us</Button>
                    </div>
                </Col>
            </Container>
            <PricingComparison
                availablePackages={availablePackages}
                handleClick={handleSelectPackage}
                getPackageButtonText={() => "Select"}
                currency={currency}
                activePaymentOption={activePaymentOption}
            // languageCode="en"
            />
        </>
    );
};

export default PricingPage;
