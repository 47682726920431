import { useQuery } from "react-query";

import { PACKAGES_CACHE } from "constants/cache";
import axios from "axios";
import { IPackages } from "./models";
import { API_URL } from "config";

export const useGetPackages = () => {
    return useQuery<IPackages>([PACKAGES_CACHE], () =>
        axios
            .get(`${API_URL}/web_api/available_packages`) // use env
            .then((response) => {
                return response.data["hydra:member"]?.[0] ?? response.data;
            })
    );
};
