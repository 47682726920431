import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { LanguageProvider } from "context/LanguageProvider";
import PricingPage from "./PricingPage/PricingPage";
import "./App.css";

function App() {
    return (
        <div className="App">
            <Router>
                <LanguageProvider>
                    <QueryClientProvider client={new QueryClient()}>
                        <PricingPage />
                    </QueryClientProvider>
                </LanguageProvider>
            </Router>
        </div>
    );
}

export default App;
