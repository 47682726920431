import React from "react";
import { Col, Button } from "react-bootstrap";
import classNames from "classnames";

import styles from "../PricingPage.module.scss";

interface IPaymentOption {
    id: string;
    label: string;
}
export const paymentOptions: IPaymentOption[] = [
    { id: "year", label: "Yearly payment" },
    { id: "month", label: "Monthly payment" },
];

export interface IPaymentOptionButtonsProps {
    activePaymentOption: string;
    setActivePaymentOption: React.Dispatch<React.SetStateAction<string>>;
}

export const PaymentOptionButtons = ({
    activePaymentOption,
    setActivePaymentOption,
}: IPaymentOptionButtonsProps) => {
    return (
        <Col
            xs={12}
            className={classNames(
                styles.paymentOptionButtons,
                "text-center"
            )}
        >
            {paymentOptions.map((p) => (
                <Button
                    key={p.id}
                    onClick={() => setActivePaymentOption(p.id)}
                    className={
                        activePaymentOption === p.id ? "active" : undefined
                    }
                >
                    {p.label}
                    {p.id === "year" ? (
                        <div className={styles.savingsContainer}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 27 34"
                                width="1.5rem"
                                className={classNames(
                                    styles.savingsSvg,
                                    "ml-3 mb-1"
                                )}
                            >
                                <path
                                    fill="#4F868E"
                                    d="M26.5806 6.3948l-10.1091 5.5804.2218-11.5449 9.8873 5.9646zM.6028 33.1044c-.894-4.1722-1.0233-10.5249 1.225-16.1508C4.1037 11.258 8.8182 6.3326 17.4551 5.23l.2532 1.984C9.8727 8.2141 5.7231 12.5953 3.6849 17.6956c-2.066 5.1699-1.9595 11.1023-1.1265 14.9897l-1.9556.419z"
                                />
                            </svg>
                            <div className={styles.savingsText}>
                                Save <strong>16</strong>% - 2 months for free
                            </div>
                        </div>
                    ) : null}
                </Button>
            ))}
        </Col>
    );
};
