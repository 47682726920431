import React from "react";
import classNames from "classnames";
import { Card } from "react-bootstrap";

import { formatDate } from "helpers/dates";
import { translate } from "helpers/translate";
import Button from "components/Button";
import { IPackageDetails } from "../models";
import styles from "../PricingPage.module.scss";

export interface IPackageCardProps {
    packageData: IPackageDetails;
    activePaymentOption: string;
    currency: string;
    highlight: boolean;
    handleClick: (packageName: string) => void;
    isActivePackage: boolean;
    buttonText: string;
    packageChangedAt?: string | null;
    discountName?: string | null;
    noPaymentFees: boolean;
}

export const PackageCard = ({
    packageData,
    activePaymentOption,
    currency,
    highlight,
    handleClick,
    isActivePackage,
    buttonText,
    packageChangedAt,
    discountName,
    noPaymentFees,
}: IPackageCardProps) => {
    const renderCardData = () => {
        return (
            <ul className={styles.metaDataList}>
                {packageData.cardContent.map((m) => {
                    return (
                        <div key={packageData["@id"] + m.row_title}>
                        <p className={styles.rowTitle}>{m.row_title}</p>
                        {m.rows.map(r => (
                            <li key={packageData["@id"] + r}>
                                <span className={styles.checkMark} />
                                <span>{r}</span>
                            </li>
                        ))}
                        </div>
                    );
                })}
            </ul>
        );
    };

    const renderPrices = () => {
        if (packageData.prices instanceof Array && !packageData.prices.length) {
            return "0";
        }

        return (packageData.prices as any)[currency.toLowerCase()][
            activePaymentOption
        ].price;
    };

    return (
        <Card body className={classNames(styles.packageCard, { highlight })}>
            {highlight ? (
                <div className="highlight-text">Most popular</div>
            ) : null}
            <div className="card-item">
                <h3 className={styles.packageCardTitle}>
                    {packageData.productName}
                </h3>
                <div className={styles.packageCardPricing}>
                    <span className="currency" data-notranslate>
                        {currency}
                    </span>
                    <span className="price" data-notranslate>
                        {renderPrices()}
                    </span>
                    <span className="period">
                        {translate(`/${activePaymentOption}`)}
                    </span>
                </div>
            </div>
            <div className="card-item">
                <Button
                    className={styles.upgradeButton}
                    onClick={() => handleClick(packageData.productName)}
                    disabled={isActivePackage}
                >
                    {buttonText}
                </Button>
                {renderCardData()}
            </div>
            <div
                className={classNames(styles.packageCardFooter, {
                    [styles.multiline]:
                        [
                            !!packageChangedAt,
                            !!discountName,
                            !!noPaymentFees,
                        ].filter(Boolean).length >= 2,
                })}
            >
                {isActivePackage && packageChangedAt ? (
                    <div>
                        Last update:{" "}
                        <span data-notranslate>
                            {formatDate(packageChangedAt)}
                        </span>
                    </div>
                ) : null}
                {isActivePackage && discountName ? (
                    <div>Special agreement: {discountName}</div>
                ) : null}
                {isActivePackage && noPaymentFees ? (
                    <div>Special agreement: No payment fees</div>
                ) : null}
            </div>
        </Card>
    );
};
