import React, {
    useEffect,
    createContext,
    useContext,
    useMemo,
    useCallback,
} from 'react';
import classNames from 'classnames';

import useAccordion, {
    AccordionContextType,
} from './useAccordion';
import styles from './Accordion.module.scss';

export type ToggleType = 'body' | 'title';

const AccordionContext = createContext<AccordionContextType | null>(null);
AccordionContext.displayName = 'AccordionContext';

interface IProps {
    children: React.ReactNode;
    toggleMode: 'single' | 'multi';
}

const Accordion = ({children, toggleMode}: IProps) => {
    const {state, dispatch} = useAccordion();

    useEffect(() => {
        dispatch({
            type: 'SET_TOGGLE_MODE',
            payload: toggleMode,
        });
    }, [dispatch, toggleMode]);

    const values = useMemo(() => ({state, dispatch}), [state, dispatch]);

    return (
        <AccordionContext.Provider value={values}>
            {children}
        </AccordionContext.Provider>
    );
};

export interface IAccordionItemProps {
    className?: string;
    toggleType: ToggleType;
    openStatus?: boolean;
    disabled?: boolean;
    accordionKey: string;
    title: React.ReactNode;
    titleClass?: string;
    body: React.ReactNode;
    bodyClass?: string;
    titleRightDescriptionText?: string;
    openStatusChanged?: (status: boolean) => void;
}

const AccordionItem = ({
    className,
    toggleType,
    openStatus,
    disabled,
    accordionKey,
    title,
    titleRightDescriptionText,
    titleClass,
    body,
    bodyClass,
    openStatusChanged,
}: IAccordionItemProps) => {
    const {state, dispatch} = useContext(AccordionContext);

    const openState = state.open.get(accordionKey);

    useEffect(() => {
        dispatch({
            type: 'TOGGLE',
            payload: {key: accordionKey, value: openStatus},
        });
    }, [dispatch, accordionKey, openStatus]);

    const toggleOpen = useCallback(() => {
        dispatch({
            type: 'TOGGLE',
            payload: {key: accordionKey, value: !openState},
        });
        if (openStatusChanged) {
            openStatusChanged(!openState);
        }
    }, [accordionKey, dispatch, openState, openStatusChanged]);

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.closed]: !openState,
                [styles.disabled]: disabled,
            })}
            key={accordionKey}
        >
            <div className={styles.titleRow}>
                <button
                    className={classNames(styles.button, titleClass)}
                    type="button"
                    onClick={toggleOpen}
                >
                    <div className={styles.title}>{title}</div>
                    {toggleType === 'title' ? (
                        <div className={styles.titleToggle}>
                            {titleRightDescriptionText ? (
                                <span
                                    className={styles.titleRightDescriptionText}
                                    data-notranslate
                                >
                                    {titleRightDescriptionText}
                                </span>
                            ) : null}

                            {openState ? (
                                <ChevronUp />
                            ) : (
                                <ChevronDown />
                            )}
                        </div>
                    ) : null}
                </button>
            </div>
            <div className={classNames(styles.body, bodyClass)}>
                <div className={styles.innerBody}>
                    {body}
                    {toggleType === 'body' ? (
                        <div className={styles.bodyToggle}>
                            <button
                                className={styles.button}
                                type="button"
                                onClick={toggleOpen}
                            >
                                Details{' '}
                                {openState ? (
                                    <ChevronUp />
                                ) : (
                                    <ChevronDown />
                                )}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default AccordionItem;
export {Accordion};

const ChevronDown = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            style={{
                width: '1.5rem', 
                height: '1.5rem',
                fontWeight: 'bold',
                
            }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    )
}

const ChevronUp = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            style={{
                width: '1.5rem', 
                height: '1.5rem',
                fontWeight: 'bold',
                
            }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
    )
}